import { Injectable, HostListener } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

// Menu
export interface Menu {
	path?: string;
	title?: string;
	type?: string;
	megaMenu?: boolean;
	image?: string;
	active?: boolean;
	badge?: boolean;
	badgeText?: string;
	name?: string;
	children?: Menu[];
}

@Injectable({
	providedIn: 'root'
})

export class NavService {

	constructor() { }

	public screenWidth: any;
	public leftMenuToggle: boolean = false;
	public mainMenuToggle: boolean = false;

	// Windows width
	@HostListener('window:resize', ['$event'])
	onResize(event?) {
		this.screenWidth = window.innerWidth;
	}

	MENUITEMS: Menu[] = [
		{
			title: 'categories', type: 'sub', active: false, children: [
				{
					title: 'clothing', type: 'sub', active: false, children: [
						{ path: '/home/fashion', title: 'fashion-01', type: 'link' },
					]
				},
				{ path: '/shop/collection/left/sidebar', title: 'Apparel', type: 'link', name: 'Apparel' },
				{ path: '/shop/collection/left/sidebar', title: 'art', type: 'link', name:' Art & Craft' },
				{ path: '/shop/collection/left/sidebar', title: 'bags', type: 'link' , name: 'Hand Bags'},
				{ path: '/shop/collection/left/sidebar', title: 'electronics', type: 'link', name: 'Electronics'},
				{ path: '/shop/collection/left/sidebar', title: 'food', type: 'link', name: 'Food' },
				{ path: '/shop/collection/left/sidebar', title: 'Fragrance', type: 'link', name:'Fragrances' },
				{ path: '/shop/collection/left/sidebar', title: 'home', type: 'link', name: 'Home & Kitchen' },
				{ path: '/shop/collection/left/sidebar', title: 'jewellery', type: 'link', name:'Jewellery' },
				{ path: '/shop/collection/left/sidebar', title: 'kids', type: 'link' , name: 'Kids' },
				{ path: '/shop/collection/left/sidebar', title: 'office', type: 'link', name: 'Office Supplies'  },
				{ path: '/shop/collection/left/sidebar', title: 'pets', type: 'link' , name: 'Pets'},
				{ path: '/shop/collection/left/sidebar', title: 'shoes', type: 'link' , name: 'Shoes'},
				{ path: '/shop/collection/left/sidebar', title: 'tools', type: 'link' , name: 'Tools' },
			]
		},
		// {
		// 	title: 'ubqari products', type: 'sub', active: false, children: [
		// 		{ path: '/shop/product/three/column/trim-dress', title: 'Books', type: 'link' },
		// 		{ path: '/shop/product/four/image/belted-dress', title: 'Medicines', type: 'link' },
		// 		{ path: '/shop/product/bundle/trim-dress', title: 'gifts', type: 'link' },
		// 		{ path: '/shop/product/image/outside/trim-dress', title: 'other products', type: 'link' }
		//		{ path: '/pages/comingsoon', title: 'comingsoon', name:'Coming Soon', type: 'link', badge: true, badgeText: 'new' },
		// 	]
		// },
		{
			title: 'More', type: 'sub', active: false, children: [
				{ path: '/pages/aboutus', title: 'aboutus', type: 'link', name:'About US' },
				{ path: '/pages/faq', title: 'faq', type: 'link' , name: 'FAQ'},
				{ path: '/pages/contact', title: 'contact', type: 'link' , name:'Contact US'},
			]
		}
	];

	// LEFTMENUITEMS: Menu[] = [
	// 	{
	// 		title: 'clothing', type: 'sub', megaMenu: true, active: false, children: [
	// 			{
	// 				title: 'mens fashion', type: 'link', active: false, children: [
	// 					{ path: '/home/fashion', title: 'sports wear', type: 'link' },
	// 					{ path: '/home/fashion', title: 'top', type: 'link' },
	// 					{ path: '/home/fashion', title: 'bottom', type: 'link' },
	// 					{ path: '/home/fashion', title: 'ethic wear', type: 'link' },
	// 					{ path: '/home/fashion', title: 'sports wear', type: 'link' },
	// 					{ path: '/home/fashion', title: 'shirts', type: 'link' },
	// 					{ path: '/home/fashion', title: 'bottom', type: 'link' },
	// 					{ path: '/home/fashion', title: 'ethic wear', type: 'link' },
	// 					{ path: '/home/fashion', title: 'sports wear', type: 'link' }
	// 				]
	// 			},
	// 			{
	// 				title: 'women fashion', type: 'link', active: false, children: [
	// 					{ path: '/home/fashion', title: 'dresses', type: 'link' },
	// 					{ path: '/home/fashion', title: 'skirts', type: 'link' },
	// 					{ path: '/home/fashion', title: 'westarn wear', type: 'link' },
	// 					{ path: '/home/fashion', title: 'ethic wear', type: 'link' },
	// 					{ path: '/home/fashion', title: 'bottom', type: 'link' },
	// 					{ path: '/home/fashion', title: 'ethic wear', type: 'link' },
	// 					{ path: '/home/fashion', title: 'sports wear', type: 'link' },
	// 					{ path: '/home/fashion', title: 'sports wear', type: 'link' },
	// 					{ path: '/home/fashion', title: 'bottom wear', type: 'link' }
	// 				]
	// 			},
	// 		]
	// 	},
	// 	{
	// 		title: 'bags', type: 'sub', active: false, children: [
	// 			{ path: '/home/fashion', title: 'shopper bags', type: 'link' },
	// 			{ path: '/home/fashion', title: 'laptop bags', type: 'link' },
	// 			{ path: '/home/fashion', title: 'clutches', type: 'link' },
	// 			{
	// 				path: '/home/fashion', title: 'purses', type: 'link', active: false, children: [
	// 					{ path: '/home/fashion', title: 'purses', type: 'link' },
	// 					{ path: '/home/fashion', title: 'wallets', type: 'link' },
	// 					{ path: '/home/fashion', title: 'leathers', type: 'link' },
	// 					{ path: '/home/fashion', title: 'satchels', type: 'link' }
	// 				]
	// 			},
	// 		]
	// 	},
	// 	{
	// 		title: 'footwear', type: 'sub', active: false, children: [
	// 			{ path: '/home/fashion', title: 'sport shoes', type: 'link' },
	// 			{ path: '/home/fashion', title: 'formal shoes', type: 'link' },
	// 			{ path: '/home/fashion', title: 'casual shoes', type: 'link' }
	// 		]
	// 	},
	// 	{
	// 		path: '/home/fashion', title: 'watches', type: 'link'
	// 	},
	// 	{
	// 		title: 'Accessories', type: 'sub', active: false, children: [
	// 			{ path: '/home/fashion', title: 'fashion jewellery', type: 'link' },
	// 			{ path: '/home/fashion', title: 'caps and hats', type: 'link' },
	// 			{ path: '/home/fashion', title: 'precious jewellery', type: 'link' },
	// 			{
	// 				path: '/home/fashion', title: 'more..', type: 'link', active: false, children: [
	// 					{ path: '/home/fashion', title: 'necklaces', type: 'link' },
	// 					{ path: '/home/fashion', title: 'earrings', type: 'link' },
	// 					{ path: '/home/fashion', title: 'rings & wrist wear', type: 'link' },
	// 					{
	// 						path: '/home/fashion', title: 'more...', type: 'link', active: false, children: [
	// 							{ path: '/home/fashion', title: 'ties', type: 'link' },
	// 							{ path: '/home/fashion', title: 'cufflinks', type: 'link' },
	// 							{ path: '/home/fashion', title: 'pockets squares', type: 'link' },
	// 							{ path: '/home/fashion', title: 'helmets', type: 'link' },
	// 							{ path: '/home/fashion', title: 'scarves', type: 'link' },
	// 							{
	// 								path: '/home/fashion', title: 'more...', type: 'link', active: false, children: [
	// 									{ path: '/home/fashion', title: 'accessory gift sets', type: 'link' },
	// 									{ path: '/home/fashion', title: 'travel accessories', type: 'link' },
	// 									{ path: '/home/fashion', title: 'phone cases', type: 'link' }
	// 								]
	// 							},
	// 						]
	// 					}
	// 				]
	// 			},
	// 		]
	// 	},
	// 	{
	// 		path: '/home/fashion', title: 'house of design', type: 'link'
	// 	},
	// 	{
	// 		title: 'beauty & personal care', type: 'sub', active: false, children: [
	// 			{ path: '/home/fashion', title: 'makeup', type: 'link' },
	// 			{ path: '/home/fashion', title: 'skincare', type: 'link' },
	// 			{ path: '/home/fashion', title: 'premium beaty', type: 'link' },
	// 			{
	// 				path: '/home/fashion', title: 'more..', type: 'link', active: false, children: [
	// 					{ path: '/home/fashion', title: 'fragrances', type: 'link' },
	// 					{ path: '/home/fashion', title: 'luxury beauty', type: 'link' },
	// 					{ path: '/home/fashion', title: 'hair care', type: 'link' },
	// 					{ path: '/home/fashion', title: 'tools & brushes', type: 'link' }
	// 				]
	// 			},
	// 		]
	// 	},
	// 	{
	// 		path: '/home/fashion', title: 'home & decor', type: 'link'
	// 	},
	// 	{
	// 		path: '/home/fashion', title: 'kitchen', type: 'link'
	// 	}
	// ];

	// Array
	items = new BehaviorSubject<Menu[]>(this.MENUITEMS);
	// leftMenuItems = new BehaviorSubject<Menu[]>(this.LEFTMENUITEMS);

}
