import { Component, PLATFORM_ID, Inject } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { map, delay, withLatestFrom } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import WooCommerceRestApi from "@woocommerce/woocommerce-rest-api";
import { WoApi, WoApiOrder, ImaanWoApi } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  
  // For Progressbar
  loaders = this.loader.progress$.pipe(
    delay(1000),
    withLatestFrom(this.loader.progress$),
    map(v => v[1]),
  );
  
  constructor(@Inject(PLATFORM_ID) private platformId: Object,
    private loader: LoadingBarService, translate: TranslateService) {
    if (isPlatformBrowser(this.platformId)) {
      translate.setDefaultLang('en');
      translate.addLangs(['en', 'fr']);
    }

  //   console.log('Coming');
  //   const api = new WooCommerceRestApi(ImaanWoApi);
  //   api.get("products",{"category": 77, "per_page": 100})
  // .then((response) => {
  //   console.log(response);
  // })
  // .catch((error) => {
  //   console.log(error.response.data);
  // });
  }

  addUser(){
    const api = new WooCommerceRestApi(WoApiOrder);
    var data = {
        email: 'calldrgoof@gmail.com',
    };

    api.post("customers", data)
    .then((response) => {
      console.log(response.data);
    })
    .catch((error) => {
      console.log(error.response.data);
    });
    alert('Hello');
  }


  makeOrder(){
    const api = new WooCommerceRestApi(WoApiOrder);


    const data = {
      payment_method: "bacs",
      payment_method_title: "Over the Phone Verification Needed",
      set_paid: false,
      status: "on-hold",
      billing: {
        first_name: "Shahab",
        last_name: "Sheikh",
        address_1: "969 Market",
        address_2: "",
        city: "San Francisco",
        state: "CA",
        postcode: "94103",
        country: "US",
        email: "shahabsheikh1@gmail.com",
        phone: "(555) 555-5555"
      },
      shipping: {
        first_name: "Imaan",
        last_name: "Store",
        address_1: "1 Market",
        address_2: "",
        city: "San Francisco",
        state: "CA",
        postcode: "94103",
        country: "US"
      },
      line_items: [
        {
          product_id:15,
          quantity: 2
        },
        {
          product_id: 14,
          quantity: 1
        }
      ],
      shipping_lines: [
        {
          method_id: "flat_rate",
          method_title: "Flat Rate",
          total: "10.00"
        }
      ]
    };



    api.post("orders", data)
    .then((response) => {
      console.log(response.data);
    })
    .catch((error) => {
      console.log(error.response.data);
    });
    alert('Hello');

    
  }

}
