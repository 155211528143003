import { BigAddUser } from './../../../../environments/environment';
import WooCommerceRestApi from "@woocommerce/woocommerce-rest-api";
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-footer-one',
  templateUrl: './footer-one.component.html',
  styleUrls: ['./footer-one.component.scss']
})
export class FooterOneComponent implements OnInit {

  @Input() class: string = 'footer-light' // Default class 
  @Input() themeLogo: string = 'assets/images/icon/logo.png' // Default Logo
  @Input() newsletter: boolean = true; // Default True

  public today: number = Date.now();
  public email : string = '';

  constructor() { }

  ngOnInit(): void {
  }


  addUser(){


    if(this.email !== ''){
      const api = new WooCommerceRestApi(BigAddUser);
      var data = {
          email: this.email
      };
  
      api.post("customers", Headers, data )
      .then((response) => {
        console.log(response.data);
      })
      .catch((error) => {
        if( error.response.data.code === 'registration-error-email-exists'){
          alert('User Already Exist ')
        }
      });
  
    }else{
      alert('Please Enter email')
    }





  }

}
