// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import axios from 'axios'

export const environment = {
  production: false,
  instagram_token: 'INSTA_TOKEN',
  stripe_token: 'STRIPE_TOKEN',
  paypal_token: 'PAYPAL_TOKEN'
};

export const WoApi ={
  url: "https://drgoof.com/drgoof",
  consumerKey: "ck_bba7c608fcc7bef9ddf1c1ffb4b0805db672a9da",
  consumerSecret: "cs_f9683aa97d5f1b674317e9ac5c7877d87f79020c",
  version: "wc/v3"
}

export const BigWoApi ={
  url: "https://bigonlinedepot.com/bigonlinedepot",
  consumerKey: "ck_1c0bf58198d3125be881ea9be37f5141073e7acb",
  consumerSecret: "cs_3b26eeb621acf9291ff8c45d03a1eb0d3329f265",
  version: "wc/v3",
  wpAPI: true,
  axiosConfig: {
	  headers: axios.interceptors.request.use(function (config) {
      const { headers = {} } = config || {}
      if (headers['User-Agent']) delete config.headers['User-Agent']
    
      return config
    })
	}
}

// let configs = axios.interceptors.request.use(function (config) {
//   const { headers = {} } = config || {}
//   if (headers['User-Agent']) delete config.headers['User-Agent']

//   return config
// })


export const BigAddUser ={
  url: "https://bigonlinedepot.com/bigonlinedepot",
  consumerKey: "ck_2efe6c3e20c58d40ee2f4a9215c6f7a2bdbfeb04",
  consumerSecret: "cs_78990880c912975ad2125bc0fed921dd9dfad9c1",
  version: "wc/v3",
  wpAPI: true,
  axiosConfig: {
	  headers: { }
	}
}


export const BigAddOrder ={
  url: "https://bigonlinedepot.com/bigonlinedepot",
  consumerKey: "ck_2efe6c3e20c58d40ee2f4a9215c6f7a2bdbfeb04",
  consumerSecret: "cs_78990880c912975ad2125bc0fed921dd9dfad9c1",
  version: "wc/v3",
  wpApI: true,
  axiosConfig: {
	  headers: { 'Content-Type': 'application/json' }
	},
  queryStringAuth: true

}


// export const BigAddUser ={
//   url: "https://bigonlinedepot.com/bigonlinedepot",
//   consumerKey: "ck_2efe6c3e20c58d40ee2f4a9215c6f7a2bdbfeb04",
//   consumerSecret: "cs_78990880c912975ad2125bc0fed921dd9dfad9c1",
//   version: "wc/v3",
//   wpAPI: true,
//   axiosConfig: {
// 	  headers: axios.interceptors.request.use(function (config) {
//       const { headers = {} } = config || {}
//       if (headers['User-Agent']) delete config.headers['User-Agent']
    
//       return config
//     })
//   }
// }



export const WoApiOrder ={
  url: "https://drgoof.com",
  consumerKey: "ck_e635e6f17002adf3940ff3863621a47d5a2c08e7",
  consumerSecret: "cs_68f05e636627d8f26c64d559867cf7ba62616f68",
  version: "wc/v3",
  wpAPI: true,
  axiosConfig: {
	  headers: { }
	}
}

export const ImaanWoApi = {
  url: "https://imaanstore.com",
  consumerKey: "ck_c58548236f8b507c4adb6e0f5aec9e12c2608101",
  consumerSecret: "cs_3d88bc8faa3a9965d5e15bfb815a2051b42e4e2f",
  version: "wc/v3"
}

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
