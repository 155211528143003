<header class="sticky" [ngClass]="class" [class.fixed]="stick && sticky">
  <div class="mobile-fix-option"></div>
  <div class="top-header" *ngIf="topbar">
    <div class="container">
      <div class="row">
        <div class="col-lg-6">
          <div class="header-contact">
            <ul>
              <li>Welcome to Our Big Online Depot</li>
              <li><i class="fa fa-phone" aria-hidden="true"></i>Call Us: 929 - 224 - 4001</li>
            </ul>
          </div>
        </div>
        <div class="col-lg-6 text-right">
          
        </div>
      </div>
    </div>
  </div>
  <div class="container">
    <div class="row">
      <div class="col-sm-12">
        <div class="main-menu">
          <div class="menu-left">
            <app-left-menu></app-left-menu>
            <div class="brand-logo">
              <a routerLink="/home/fashion" id="headerlogo">
                <img [src]="themeLogo" class="image-fluid-logo" alt="logo">
              </a>
            </div>
          </div>
          <div class="menu-right pull-right">
            <div class="quote-text-menu">
            - Fragrance is the key to Our Memories
            </div>
            <div>
              <div class="menu-right pull-right">
                <app-settings></app-settings>
            </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</header>
