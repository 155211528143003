import { Component, OnInit, AfterViewInit } from '@angular/core';
import { take } from 'rxjs/operators';
import { Product } from '../../classes/product';
import { ProductService } from '../../services/product.service';

@Component({
  selector: 'app-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.scss']
})
export class CategoriesComponent implements OnInit, AfterViewInit {

  public products: Product[] = [];
  public collapse: boolean = true;
  public imaanproduct: any[] = [];
  public categoriesList: any[] = [
    'Apparel', 'Art','Bags', 'Electronics','Food', 'Fragrance', 'Home', 'Jewellery', 'Kids', 'Office', 'Pets', 'Shoes', 'Tools'
  ]

  constructor(public productService: ProductService) {
    this.productService.Imaanproducts.pipe(take(4)).subscribe(product => {
      this.imaanproduct = [];
      product.data.forEach(items=> {
        let formatedItems : any = {};
          formatedItems.id = items.id
          formatedItems.title = items.name
          formatedItems.description = items.description
          if(items.categories[1]){
            formatedItems.collection = [items.categories[1].name]
          }
          
          formatedItems.category = items.categories[0].name
          formatedItems.price = items.price
          formatedItems.sale = items.on_sale
          formatedItems.discount = items.sale_price
          formatedItems.images = items.images

          //console.log(items,'single items')
          this.imaanproduct.push(formatedItems)
    });
  }) 

    // this.productService.getProducts.subscribe(product => this.products = product);
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(){
    
  }
  

  get filterbyCategory() {
    const category = [...new Set(this.products.map(product => product.type))]
    return category
  }

  get ImaanfilterbyCategory() {
    const category = [...new Set(this.imaanproduct.map(product => product.category))]
    return category
  }

}
