<!--footer section -->
<footer [class]="class">
	<div class="light-layout" *ngIf="newsletter">
		<div class="container">
			<section class="small-section border-section border-top-0">
				<div class="row">
					<div class="col-lg-6">
						<div class="subscribe">
							<div>
								<h4>KNOW IT ALL FIRST!</h4>
								<p>Never Miss Anything From Big Online Depot By Signing Up To Our Newsletter. </p>
							</div>
						</div>
					</div>
					<div class="col-lg-6">
						<form class="form-inline subscribe-form">
							<div class="form-group mx-sm-3">
								<input type="email" #name name="EMAIL" id="mce-EMAIL" class="form-control" [(ngModel)]="email" placeholder="Enter your email">
							</div>
							<button (click)="addUser()" class="btn btn-solid">subscribe</button>
						</form>
					</div>
				</div>
			</section>
		</div>
	</div>
	<section class="section-b-space light-layout">
		<div class="container">
			<div class="row footer-theme partition-f">
				<div class="col-lg-4 col-md-6">
					<div class="footer-title footer-mobile-title">
						<h4>about</h4>
					</div>
					<div class="footer-contant">
						<div class="footer-logo">
							<img [src]="themeLogo" alt="logo">
						</div>
						<p>When it comes to our customers Big Online Depot puts up the best effort to bring all products our customers are looking for. We bring our customer the best producsts out there and we believe providing best is what we do</p>
						<div class="footer-social">
                            <ul>
                                <li>
                                    <a href="https://www.facebook.com/bigonlinedepot"><i class="fa fa-facebook" aria-hidden="true"></i></a>
                                </li>
                                <li>
                                    <a href="https://twitter.com/bigoninedepot"><i class="fa fa-twitter" aria-hidden="true"></i></a>
                                </li>
                                <li>
                                    <a href="https://www.instagram.com/bigonlinedepot/?hl=en"><i class="fa fa-instagram" aria-hidden="true"></i></a>
                                </li>
                            </ul>
                        </div>
					</div>
				</div>
				<div class="col offset-xl-1">
					<div class="sub-title">
						<div class="footer-title">
                            <h4></h4>
                        </div>
                        <div class="footer-contant">
                            <ul>
                                <li><a href="javascript:void(0)"></a></li>
                                <li><a href="javascript:void(0)"></a></li>
								<li><a href="javascript:void(0)"></a></li>
                                <li><a href="javascript:void(0)"></a></li>
                            </ul>
                        </div>
					</div>
				</div>
				<div class="col">
					<div class="sub-title">
						<div class="footer-title">
                            <h4>Useful Links</h4>
                        </div>
                        <div class="footer-contant">
                            <ul>
                                <li [routerLink]="['/pages/terms']" ><a>Terms & Conditions</a></li>
                                <li [routerLink]="['/pages/privacy']"><a>Privacy Policy</a></li>
                                <li [routerLink]="['/pages/shipping']"><a></a>Shipping Policy</li>
                                <li [routerLink]="['/pages/return']"><a></a>Return Policy</li>
                            </ul>
                        </div>
					</div>
				</div>
				<div class="col">
					<div class="sub-title">
						<div class="footer-title">
						    <h4>store information</h4>
						</div>
						<div class="footer-contant">
						    <ul class="contact-list">
						        <li><i class="fa fa-map-marker"></i>Warren, NJ 07059</li>
						        <li><i class="fa fa-phone"></i>+1(929)-224-4001</li>
						        <li><i class="fa fa-envelope-o"></i> <a>info@bigonlinedepot.com</a></li>
						    </ul>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
	<div class="sub-footer">
		<div class="container">
			<div class="row">
                <div class="col-xl-12 col-md-12 col-sm-12">
                  <div class="footer-end">
                    <p style="text-align: center;"><i class="fa fa-copyright" aria-hidden="true"></i> {{ today | date:'y'}} Big Online Depot powered by Dr.Goof</p>
                  </div>
                </div>
            </div>
		</div>
	</div>
</footer>
<!--footer section end -->